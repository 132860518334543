<div class="wsmenucontainer clearfix">
<div class="overlapblackbg"></div>


  <div class="wsmobileheader clearfix"></div>

        <div id="header" class="headerfull">
    	   <div class="wsmain">



           <nav class="wsmenu">
                <ul class="mobile-sub wsmenu-list">
                  <li>
                    <div class="smllogo">
                        <a routerLink="./">
                          <img class="color" src="../../assets/img/esource.svg" alt="esource"/>
                        </a>

                    </div>
                  </li>
                  <li><a>{{ 'cloud.title28' | translate }}<span class="arrow"></span></a>
                    <ul class="wsmenu-submenu">
                      <li><a routerLink="./somos" href="#">{{ 'cloud.title95' | translate }}</a></li>
                      <!--<li><a (click)="showing('https://amarello.cloud/es/','Amarello')" target="_blank">Amarello</a></li>-->
                      <!--<li><a (click)="showing('https://www.escala24x7.com/','Escala 24x7')" target="_blank">Escala 24x7</a></li>-->
                      <li><a (click)="showing('https://www.ispcloudservices.com/','ISP Cloud Services')"  target="_blank">ISP Cloud Services</a></li>
                      <li><a (click)="showing('http://www.ptree.com.mx/','PTree')" target="_blank">PTree</a></li>
                      <!--<li><a (click)="showing('http://www.daxosline.com/','Daxos')" target="_blank">Daxos</a></li>-->
<!--                       <li><a href="https://www.gowplace.com/" target="_blank">Gowplace</a></li> -->
                    </ul>
                  </li>
                  <li><a>{{ 'cloud.title29' | translate }}<span class="arrow"></span></a>
                    <ul class="wsmenu-submenu">
                      <li><a routerLink="./acceleracion-digital">{{ 'cloud.title96' | translate }}</a></li>
                      <li><a routerLink="./customer">Customer for Life</a></li>
                      <li><a (click)="showing('https://amarello.cloud/es/smart-business-analytics','Business Analytics & Machine Learning')" target="_blank">Business Analytics & Machine Learning</a></li>
                      <li><a (click)="showing('https://amarello.cloud/es/industrias/finance/open-banking','Open Banking / Open Finance')" target="_blank">Open Banking / Open Finance</a></li>
                      <li><a routerLink="./migracion-sap">SAP on GCP</a></li>
                      <li><a (click)="showing('https://amarello.cloud/es/otras-soluciones/anthos','Anthos Implementation')" target="_blank">{{ 'cloud.title97' | translate }}</a></li>
                      <li><a (click)="showing('https://amarello.cloud/es/otras-soluciones/apigee','Apigee Implementation')" target="_blank">{{ 'cloud.title98' | translate }}</a></li>
                      <li><a routerLink="./appsheet">Appsheet</a></li>
                    </ul>
                  </li>




                  <li><a>{{ 'cloud.title30' | translate }}<span class="arrow"></span></a>

                    <div class="megamenu">

                        <ul class="link-list">
                          <li class="title">{{ 'cloud.title99' | translate }}</li>
                          <li><a routerLink="./workspace">Google Workspace</a></li>

                          <li><a routerLink="./education">Google for Education</a></li>
                          <li><a routerLink="./microsoft365">Microsoft 365</a></li>

                          <li><a routerLink="/rackspace">Rackspace Email</a></li>
                          <li class="much"><a>{{ 'cloud.title100' | translate }}</a>
                            <ul>
                              <li>
                                <a (click)="showing('https://www.freshworks.com/latam/','Freshworks')" target="_blank">
                                  <img class="freshworks" src="../../assets/img/logos2/freshworks.svg" alt="">
                                </a>
                              </li>
                              <li>
                                <a (click)="showing('https://www.copper.com/','Copper')" target="_blank">
                                  <img class="copper" src="../../assets/img/logos2/copper.svg" alt="">
                                </a>
                              </li>
                              <li>
                                <a (click)="showing('https://www.lumapps.com/','Lumapps')" target="_blank">
                                  <img class="lumapps" src="../../assets/img/logos2/lumapps.svg" alt="">
                                </a>
                              </li>
                              <li>
                                <a (click)="showing('https://es.smartsheet.com/','Smartsheet')" target="_blank">
                                  <img class="smartsheet" src="../../assets/img/logos2/smartsheet.svg" alt="">
                                </a>
                              </li>
                              <li>
                                <a (click)="showing('https://www.lucidchart.com/pages/es','Lucidchart')"  target="_blank">
                                  <img class="lucidchart" src="../../assets/img/logos2/lucidshart.svg" alt="">
                                </a>
                              </li>
                              <li>
                                <a (click)="showing('https://www.odoo.com/es_ES','Odoo')" target="_blank">
                                  <img class="odoo" src="../../assets/img/logos2/odoo.webp" alt="odoo">
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <ul class="link-list">
                          <li class="title">Infrastructure as a Service</li>
                          <!--<li><a routerLink="./gcp">Google Cloud Platform</a></li>-->
                          <li><a routerLink="./azure">Microsoft Azure</a></li>
                        </ul>
                        <ul class="link-list">
                          <li class="title">{{ 'cloud.title101' | translate }}</li>
                          <li><a (click)="showing('https://www.tooglebox.com/','ToogleBox')" target="_blank">ToogleBox</a></li>
                          <!--<li><a (click)="showing('https://www.bettercloud.com/','Bettercloud')" target="_blank">Bettercloud</a></li>-->
                          <li><a (click)="showing('https://www.backupify.com/','Backupify')" target="_blank">Backupify</a></li>
                          <li><a (click)="showing('https://www.virtru.com/','Virtru')"  target="_blank">Virtru</a></li>
                        </ul>

                        <ul class="link-list">
                          <li class="title">{{ 'cloud.title102' | translate }}</li>
                          <li><a (click)="showing('https://info.amarello.cloud/prueba-anteater','e-Commerce search engine')" target="_blank">{{ 'cloud.title103' | translate }}</a></li>
                          <li><a (click)="showing('https://amarello.cloud/es/search-solutions/website-search','Website search engine')" href="" target="_blank">{{ 'cloud.title104' | translate }}</a></li>
                          <li><a (click)="showing('https://amarello.cloud/es/search-solutions/document-search','Document search & understanding')"href="" target="_blank">{{ 'cloud.title105' | translate }}</a></li>

                        </ul>
                        <ul class="link-list">
                          <li class="title">{{ 'cloud.title106' | translate }}</li>
                          <li><a routerLink="./maps">Google Maps</a></li>
                        </ul>
                    </div>

                  </li>
                  <li><a routerLink="./casos-exito">{{ 'cloud.title31' | translate }}</a></li>
                  <li><a routerLink="./blog">Blog</a></li>
                  <li><a href="https://helpdesk.esourcecapital.com/support/home" target="_blank">{{ 'cloud.title32' | translate }}</a></li>

                </ul>
              </nav>

              <nav class="xmenu">
                <ul class="xmenu-list">
                  <li><a class="contact" routerLink="./contacto">{{ 'cloud.title33' | translate }}</a></li>
                </ul>

                <div class="select-box">
                      <div class="select-box__current"  tabindex="1">
                        <div class="select-box__value" (click)="useLanguage('es');updateSrc(first_url);">
                          <input class="select-box__input" type="radio" id="0" value="1" name="Ben" checked="checked"/>
                          <p class="select-box__input-text">Es</p>
                        </div>
                        <div class="select-box__value" (click)="useLanguage('en');updateSrc(second_url);">
                          <input class="select-box__input" type="radio" id="1" value="2" name="Ben"/>
                          <p class="select-box__input-text">En</p>
                        </div>
                        <div class="select-box__value">
                          <input class="select-box__input" type="radio" id="2" value="3" name="Ben"/>
                          <p class="select-box__input-text">It</p>
                        </div>
                        <img class="select-box__icon" src="http://cdn.onlinewebfonts.com/svg/img_295694.svg" alt="Arrow Icon" aria-hidden="true"/>
                      </div>

                      <ul class="select-box__list">
                        <li>
                          <label class="select-box__option" for="0" aria-hidden="aria-hidden">Es</label>
                        </li>
                        <li>
                          <label class="select-box__option" for="1" aria-hidden="aria-hidden">En</label>
                        </li>
                        <li>
                          <a href="https://esourcecapital.it/">
                          <label class="select-box__option" aria-hidden="aria-hidden">It</label>
                          </a>
                        </li>
                      </ul>
                    </div>


              </nav>

	  	</div>
    </div>

</div>
