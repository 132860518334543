<div class="fondo8">
	<footer class="footer">
		<div class="wrap">
			<div class="w100">

<!--             <div class="myclass">{{ todaydate }}</div> -->

				<div>
					<div class="footer-flex gray">
						<a href="https://www.linkedin.com/company/esourcecapital" class="social" target="_blank">
							<img class="blue" src="../../assets/img/media/linkedin-azul.svg" alt="">
							<img src="../../assets/img/media/linkedin.svg" alt="">
						</a>
						<a href="https://www.youtube.com/user/esourcecapital" class="social" target="_blank">
							<img class="blue" src="../../assets/img/media/youtube-azul.svg" alt="">
							<img src="../../assets/img/media/youtube.svg" alt="">
						</a>
						<a href="https://www.facebook.com/esourcecapital?fref=ts" class="social" target="_blank">
							<img class="blue" src="../../assets/img/media/facebook-azul.svg" alt="">
							<img src="../../assets/img/media/facebook.svg" alt="">
						</a>
						<a href="https://twitter.com/esourcecapital" class="social" target="_blank">
							<img class="blue" src="../../assets/img/media/twitter-azul.svg" alt="">
							<img src="../../assets/img/media/twitter.svg" alt="">
						</a>
					</div>

					<div class="flex21">
						<ul>
							<li><a href="./privacidad" target="_blank"><h3>{{ 'cloud.title90' | translate }}</h3></a></li>
<!-- 							<li><a href="https://www.esourcecapital.com/es/trabaja-con-nosotros" target="_blank"><h3>{{ 'cloud.title91' | translate }}</h3></a></li> -->
							<li><a routerLink="./contacto"><h3>{{ 'cloud.title93' | translate }}</h3></a></li>
						</ul>
					</div>
					<div class="w100">
						<div class="pie">
							<h4>{{ 'cloud.title94' | translate }}</h4>
						</div>
					</div>
				</div>

			</div>
		</div>
	</footer>
</div>

