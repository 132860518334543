import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MyserviceService } from './myservice.service';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  posts = [];
  constructor(
    private router: Router,
    private myservice: MyserviceService,
    private metaTagService: Meta,
    private http: HttpClient,
    private translate: TranslateService
  ){ translate.addLangs(['es','en']);
     translate.setDefaultLang('es'); }

  ngOnInit(): void{

  }

  ngAfterViewInit(): void{
  }

}
