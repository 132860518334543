<div class="red">
	<button id="hamb" class="c-hamburger c-hamburger--htx">
		<span>toggle menu</span>
	</button>
	<div class="ilogo">
        <a class="ilink" routerLink="./">
      	    <img src="../../../../assets/img/esource.svg" alt="esource"/>
        </a>
    </div>

	<nav id="nav" class="sub-menu open">

	  <ul class="list-unstyled">
	  	<li id="subb">
	      <a class="link" routerLink="./">Home</a>
	  	</li>
	    <li id="subb">
	      <a class="link">{{ 'cloud.title28' | translate }}</a>
	      <img id="iplus" class="splus" src="../../assets/img/splus.svg" alt="">
	      <ul id="smenu" >
	      	<li>
	      		<a class="link ilink" routerLink="./somos">{{ 'cloud.title95' | translate }}</a>
	      	</li>
	      	<li>
	      		<a class="link ilink" href="https://amarello.cloud/es/" target="_blank">Amarello</a>
	      	</li>
	      	<!--<li>
	      		<a class="link ilink" href="https://www.escala24x7.com/" target="_blank">Escala 24x7</a>
	      	</li>-->
	      	<li>
	      		<a class="link ilink" href="https://www.ispcloudservices.com/" target="_blank">ISP Cloud Services</a>
	      	</li>
	      	<li>
	      		<a class="link ilink" href="http://www.ptree.com.mx/" target="_blank">PTree</a>
	      	</li>
          	<!--<li>
          		<a class="link ilink" href="http://www.daxosline.com/" target="_blank">Daxos</a>
          	</li>-->
          	<!--<li>
          		<a class="link ilink" href="https://www.gowplace.com/" target="_blank">Gowplace</a>
          	</li>-->
	      </ul>
	    </li>
	    <li id="subb">
	      <a class="link toogle-me">{{ 'cloud.title29' | translate }}</a>
	      <img id="iplus" class="splus" src="../../assets/img/splus.svg" alt="">
	      <ul id="smenu" >
	      	<li>
	      		<a class="link ilink" routerLink="./acceleracion-digital">{{ 'cloud.title96' | translate }}</a>
	      	</li>
	      	<li>
	      		<a class="link ilink" routerLink="./customer">Customer for Life</a>
	      	</li>
	      	<li>
	      		<a class="link ilink" href="https://amarello.cloud/es/smart-business-analytics" target="_blank">Business Analytics & Machine Learning</a>
	      	</li>
	      	<li>
	      		<a class="link ilink" href="https://amarello.cloud/es/industrias/finance" target="_blank">Open Banking / Open Finance</a>
	      	</li>
	      	<li>
	      		<a class="link ilink" routerLink="./migracion-sap">SAP on GCP</a>
	      	</li>
	      	<li>
	      		<a class="link ilink" href="https://amarello.cloud/es/otras-soluciones/anthos" target="_blank">{{ 'cloud.title97' | translate }}</a>
	      	</li>
	      	<li>
	      		<a class="link ilink" href="https://amarello.cloud/es/otras-soluciones/apigee" target="_blank">{{ 'cloud.title98' | translate }}</a>
	      	</li>

	      </ul>
	    </li>
	    <li id="subb">
	      <a class="link">{{ 'cloud.title30' | translate }}</a>
	      <img id="iplus" class="splus" src="../../assets/img/splus.svg" alt="">
	      <ul>
	      	<li id="subb">
	      		<a class="link">{{ 'cloud.title99' | translate }}</a>
	      		<img id="iplus" class="splus" src="../../assets/img/arrow-gray.svg" alt="">
	      		<ul>
	      			<li><a class="link ilink" routerLink="./workspace">Google Workspace</a></li>
	      			<li><a class="link ilink" routerLink="./education">Google for Education</a></li>
	      			<li><a class="link ilink" routerLink="./microsoft365">Microsoft 365</a></li>
	      			<li><a class="link ilink" routerLink="/rackspace">Rackspace Email</a></li>
	      			<li id="subb">
		      			<a class="link blue">{{ 'cloud.title100' | translate }}</a>
		      			<img id="iplus" class="splus" src="../../assets/img/arrow-gray.svg" alt="">
	      				<ul>
	      					<li><a href="https://www.freshworks.com/latam/" target="_blank" class="link">Freshworks</a></li>
	      					<li><a href="https://www.copper.com/" target="_blank" href="" class="link">Copper</a></li>
	      					<li><a href="https://www.lumapps.com/" target="_blank" class="link">Lumapps</a></li>
	      					<li><a href="https://es.smartsheet.com/" target="_blank" class="link">Smartsheet</a></li>
	      					<li><a href="https://www.lucidchart.com/pages/es" target="_blank" class="link">Lucidchart</a></li>
	      					<li><a href="https://www.odoo.com/es_ES" target="_blank" class="link">Odoo</a></li>
	      				</ul>
	      			</li>
	      		</ul>
	      	</li>
	      	<li id="subb">
	      		<a class="link">Infrastructure as a Service</a>
	      		<img id="iplus" class="splus" src="../../assets/img/arrow-gray.svg" alt="">
	      		<ul>
	      			<li><a class="link ilink" routerLink="./gcp">Google Cloud Platform</a></li>
	      			<li><a class="link ilink" routerLink="./azure">Microsoft Azure</a></li>
	      		</ul>
	      	</li>
	      	<li id="subb">
	      		<a class="link">{{ 'cloud.title101' | translate }}</a>
	      		<img id="iplus" class="splus" src="../../assets/img/arrow-gray.svg" alt="">
	      		<ul>
	      			<li><a class="ilink" href="https://www.tooglebox.com/" target="_blank">ToogleBox</a></li>
                    <li><a class="ilink" href="https://www.bettercloud.com/" target="_blank">Bettercloud</a></li>
                    <li><a class="ilink" href="https://www.backupify.com/" target="_blank">Backupify</a></li>
                   <!-- <li><a class="ilink" href="https://spanning.com/" target="_blank">Spanning</a></li>-->
                    <li><a class="ilink" href="https://www.virtru.com/" target="_blank">Virtru</a></li>
	      		</ul>
	      	</li>
	      	<li id="subb">
	      		<a class="link">{{ 'cloud.title102' | translate }}</a>
	      		<img id="iplus" class="splus" src="../../assets/img/arrow-gray.svg" alt="">
	      		<ul>
	      			<li><a class="ilink" href="https://info.amarello.cloud/prueba-anteater" target="_blank">{{ 'cloud.title103' | translate }}</a></li>
                    <li><a class="ilink" href="https://amarello.cloud/es/search-solutions/website-search" target="_blank">{{ 'cloud.title104' | translate }}</a></li>
                    <li><a class="ilink" href="https://amarello.cloud/es/search-solutions/document-search" target="_blank">{{ 'cloud.title105' | translate }}</a></li>
	      		</ul>
	      	</li>
	      	<li id="subb">
	      		<a class="link">{{ 'cloud.title106' | translate }}</a>
	      		<img id="iplus" class="splus" src="../../assets/img/arrow-gray.svg" alt="">
	      		<ul>
	      			<li><a class="ilink" routerLink="./maps">Google Maps</a></li>
	      		</ul>
	      	</li>
	      </ul>
	    </li>
	    <li>
	      <a class="ilink" routerLink="./casos-exito">{{ 'cloud.title31' | translate }}</a>
	    </li>
	    <li>
	      <a class="ilink" routerLink="./blog">Blog</a>
	    </li>
	    <li>
	      <a class="ilink" href="https://helpdesk.esourcecapital.com/support/home" target="_blank">Helpdesk</a>
	    </li>
	    <li>
	      <a class="ilink" routerLink="./contacto">{{ 'cloud.title33' | translate }}</a>
	    </li>
<!-- 	    <li>

	    	<div id="table">
			    <div class="tr">
			    	<a href="https://esourcecapital.com/" target="_blank">
			        	<div class="td">Esp</div>
			        </a>
			        <a href="https://esourcecapital.it/" target="_blank">
			        	<div class="td">Ita</div>
			        </a>
			    </div>
			</div>
	      
	    </li> -->
	    <div class="box">
	    	<div class="ibox">
	    		<a class="ilink" href="https://www.linkedin.com/company/esourcecapital" target="_blank">
	    			<img class="linkedin" src="../../assets/img/linkedin.svg" alt="">
	    		</a>
	    	</div>
	    	<div class="ibox">
	    		<a class="ilink" href="https://www.youtube.com/user/esourcecapital" target="_blank">
	    			<img class="youtube" src="../../assets/img/youtube.svg" alt="">
	    		</a>
	    	</div>
	    	<div class="ibox">
	    		<a class="ilink" href="https://www.facebook.com/esourcecapital?fref=ts" target="_blank">
	    			<img class="facebook" src="../../assets/img/facebook.svg" alt="">
	    		</a>
	    	</div>
	    	<div class="ibox">
	    		<a class="ilink" href="https://twitter.com/esourcecapital" target="_blank">
	    			<img class="twitter" src="../../assets/img/twitter.svg" alt="">	
	    		</a>
	    	</div>
	    </div>
	  </ul>
	</nav>
	<div class="overlay"></div>
</div>

