import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  REST_API: string ='https://backend-recaptcha-dot-gmal-api-313723.uc.r.appspot.com';

  //REST_API: string ='http://localhost:8080';

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpClient: HttpClient) { }

  geteRecptchaVal(dato) :Observable<any> {
      
  
  
    let API=`${this.REST_API}/evaluateCaptcha/`;

    return this.httpClient.post(API,dato,{headers:this.httpHeaders}) .pipe(
      catchError(this.handleError)
    )
  }
    // Error si usamos el rxjs
    handleError(error: HttpErrorResponse) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // Handle client error
        errorMessage = error.error.message;
      } else {
        // Handle server error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.log(errorMessage);
      return throwError(errorMessage);
    }

}
