// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // firebaseConfig: {
  //   apiKey: "AIzaSyDGXS-cLkvpTAr4ETHXOrpOBMIdWSE6sbE",
  //   authDomain: "pagina-web-esource-capital.firebaseapp.com",
  //   projectId: "pagina-web-esource-capital",
  //   storageBucket: "pagina-web-esource-capital.appspot.com",
  //   messagingSenderId: "832885283161",
  //   appId: "1:832885283161:web:55bfaecc26462b195b5448",
  //   measurementId: "G-LS3HRE1F74"
  // }
  firebaseConfig: {
    apiKey: "AIzaSyBKEzAb5byPCdWraNmLlIy8RYwNywM73wU",
    authDomain: "amarello-esource-web-demo.firebaseapp.com",
    databaseURL: "https://amarello-esource-web-demo.firebaseio.com",
    projectId: "amarello-esource-web-demo",
    storageBucket: "amarello-esource-web-demo.appspot.com",
    messagingSenderId: "254028792081",
    appId: "1:254028792081:web:4705f960e473588caeccb0"
  },
  apiurl:'https://blog-esource.daxosline.com.co/wordpress/index.php/wp-json/wp/v2/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
